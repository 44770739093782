<script>
export default {
  name: "Basic",
  data() {
    return {
      pdfUrl: "",
      pdfLoaded: false,
      viewerConfig: {
        sidebar: {
          viewThumbnail: true,
          viewOutline: true,
          viewAttachments: true,
        },
        secondaryToolbar: {
          secondaryPresentationMode: true,
          secondaryOpenFile: true,
          secondaryPrint: false,
          secondaryDownload: false,
          secondaryViewBookmark: true,
          firstPage: true,
          lastPage: true,
          pageRotateCw: true,
          pageRotateCcw: true,
          cursorSelectTool: true,
          cursorHandTool: true,
          scrollVertical: true,
          scrollHorizontal: true,
          scrollWrapped: true,
          spreadNone: true,
          spreadOdd: true,
          spreadEven: true,
          documentProperties: true,
        },
        toolbar: {
          toolbarViewerLeft: {
            findbar: true,
            previous: true,
            next: true,
            pageNumber: true,
          },
          toolbarViewerRight: {
            presentationMode: true,
            openFile: false,
            print: false,
            download: false,
            viewBookmark: true,
          },
          toolbarViewerMiddle: {
            zoomOut: true,
            zoomIn: true,
            scaleSelectContainer: true,
          },
        },
        errorWrapper: true,
      },
    };
  },

  mounted() {
    this.fetchPdfContent();
    // we need the : User token, Course ID, document ID (tokens or uid)
  },

  methods: {
    getBlob(file) {
      var reader = new FileReader();
      reader.readAsText(file);
      reader.onload = function() {
        console.log(reader.result);
      };
      reader.onerror = function(error) {
        console.log("Error: ", error);
      };
    },
    getBase64(file) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function() {
        console.log(reader.result);
      };
      reader.onerror = function(error) {
        console.log("Error: ", error);
      };
    },
    fetchPdfContent() {
      var queryParams = this.$route.query;
      this.$http
        .post("/secured_content/getCourseSupport", queryParams, {
          responseType: "arraybuffer",
        })
        .then((file) => {
          this.pdfUrl = file.data;
          this.pdfLoaded = true;
          document.title = "StartDocteur";
        });
    },
  },
};
</script>

<template>
  <vue-pdf-app :pdf="pdfUrl" :config="viewerConfig"></vue-pdf-app>
</template>
