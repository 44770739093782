var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"height":"95%"}},[_c('span',{staticClass:"legend prepend"},[_vm._v(" Prepend slot |")]),_c('span',{staticClass:"legend append"},[_vm._v(" Append slot")]),_c('vue-pdf-app',{staticStyle:{"position":"relative"},attrs:{"pdf":"sample.pdf"},scopedSlots:_vm._u([{key:"toolbar-left-prepend",fn:function(ref){
var toggleTheme = ref.toggleTheme;
return [_c('button',{staticClass:"prepend",attrs:{"type":"button"},on:{"click":toggleTheme}})]}},{key:"toolbar-left-append",fn:function(ref){
var toggleTheme = ref.toggleTheme;
return [_c('button',{staticClass:"append",attrs:{"type":"button"},on:{"click":toggleTheme}})]}},{key:"toolbar-middle-prepend",fn:function(ref){
var toggleTheme = ref.toggleTheme;
return [_c('button',{staticClass:"prepend",attrs:{"type":"button"},on:{"click":toggleTheme}})]}},{key:"toolbar-middle-append",fn:function(ref){
var toggleTheme = ref.toggleTheme;
return [_c('button',{staticClass:"append",attrs:{"type":"button"},on:{"click":toggleTheme}})]}},{key:"toolbar-right-prepend",fn:function(ref){
var toggleTheme = ref.toggleTheme;
return [_c('button',{staticClass:"prepend",attrs:{"type":"button"},on:{"click":toggleTheme}})]}},{key:"toolbar-right-append",fn:function(ref){
var toggleTheme = ref.toggleTheme;
return [_c('button',{staticClass:"append",attrs:{"type":"button"},on:{"click":toggleTheme}})]}},{key:"toolbar-sidebar-prepend",fn:function(ref){
var toggleTheme = ref.toggleTheme;
return [_c('button',{staticClass:"prepend",attrs:{"type":"button"},on:{"click":toggleTheme}})]}},{key:"toolbar-sidebar-append",fn:function(ref){
var toggleTheme = ref.toggleTheme;
return [_c('button',{staticClass:"append",attrs:{"type":"button"},on:{"click":toggleTheme}})]}},{key:"secondary-toolbar-prepend",fn:function(ref){
var toggleTheme = ref.toggleTheme;
return [_c('button',{staticClass:"secondary-toolbar-slot prepend",attrs:{"type":"button"},on:{"click":toggleTheme}},[_c('span',[_vm._v("Sun is shining")])])]}},{key:"secondary-toolbar-append",fn:function(ref){
var toggleTheme = ref.toggleTheme;
return [_c('button',{staticClass:"secondary-toolbar-slot append",attrs:{"type":"button"},on:{"click":toggleTheme}},[_c('span',[_vm._v("Moon is shining")])])]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"footer"},[_c('span',[_vm._v("This is a footer")])])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }